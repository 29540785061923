import { envVars } from "./constants/env.constants";

class Config {
  //process.env.NODE_ENV // this is a process to pick from node so we can pass environment variable to application // if we need to  TBD

  constructor() {
    if (window.location.origin === "http://localhost:3000") {
      this.twilioChatWorkerApi =
        "https://agentx-core-service-4137.twil.io/workersOnline?client=agentx-demo-staticweb-dev.hgsdigital.com";
    } else {
      this.twilioChatWorkerApi =
        "https://agentx-core-service-4137.twil.io/workersOnline?client=" +
        window.location.origin.replace("http://", "").replace("https://", "");
    }

    this.callCenter = JSON.parse(
      window.localStorage.getItem("staticCallCenter")
    )
      ? JSON.parse(window.localStorage.getItem("staticCallCenter"))
      : "aws"; //set Twilio by default
    this.project = JSON.parse(window.localStorage.getItem("staticProjectName"))
      ? JSON.parse(window.localStorage.getItem("staticProjectName"))
      : "finance"; //as default will load retail for RPA actions on retail if staticProjectName is not set; finance/retail/automoto/whatever.../
    this.default = `/json/${this.project}/site.config.json`;
    this.paypal = { clientId: "test" };
    // this.GET_LOGIN_API = `https://r6tnuzohd8.execute-api.us-east-1.amazonaws.com/dev/users`;
    this.GET_LOGIN_API = `https://agentx-demo-dev.hgsdigital.com/api/customer360/v1/segment/getUserDetails`;
    this.GET_USERUPDATE_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/`;
    this.GET_STATEMENTS_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/statements`;
    this.GET_SENDEMAIL_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/statements`;
    this.blogsApi = `/json/${this.project}/response/blogs.json`;
    this.promotionsApi = `/json/${this.project}/response/promotions.json`;
    this.productsApi = `/json/${this.project}/response/products.json`;
    this.myAccountApi = `/json/${this.project}/response/user.json`;

    this.readOutApi =
      "https://agentx-demo-prod.hgsdigital.com/api/core/disclosures/send-for-playback";
    // QA : "https://agentx-amex-playback-qa.hgsdigital.com/api/core/disclosures/send-for-playback";
    // "https://agentx-demo-prod.hgsdigital.com/api/core/disclosures/send-for-playback";
    //"https://agentx-demo-prod.hgsdigital.com/api/playback/v1/disclosures/send-for-playback";
    //SET FOR AMAZON CONNECT CHAT
    this.contactFlowId = "066e1dcf-57ef-43b8-8591-c85464161940"; //Connect Demo //
    this.instanceId = "0a52341e-717b-44d6-bcdd-7e17db2cab26"; //Connect Demo
    this.apiGatewayEndpoint = `https://0yprvh2zqj.execute-api.us-east-1.amazonaws.com/Prod`; //Connect Demo
    // this.moveoIntegratedId =
    //   this.callCenter === "mov"
    //     ? "02ca8301-1a23-4fbd-967c-d80efe25b83e"
    //     : "e6ed7446-5268-424a-920e-a6cfd6d1093f"; //Moveo brain ID Paw Friends

    //Moveo DEV brain ID
    this.moveoIntegratedId =
      this.callCenter === "mov" && this.project === "finance"
        ? "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"
        : this.callCenter === "movprod" && this.project === "retail"
        ? "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"
        : this.callCenter === "movprod" && this.project === "travel"
        ? "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"
        : this.callCenter === "movprod" && this.project === "insurance"
        ? "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"
        : this.callCenter === "movprod" && this.project === "telecom"
        ? "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"
        : "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1";

    //this.moveoIntegratedId = "02ca8301-1a23-4fbd-967c-d80efe25b83e"; //Moveo brain ID Paw Friends
    //this.moveoIntegratedIdProd = "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1"; //8bac22df-22c4-430c-bb9b-a8705a752559
    this.moveoHost = "https://channels-ws.dev.moveo.ai";
    this.moveoHostProd = "https://channels-ws.moveo.ai";

    this.moveoIntegratedIdProd =
      this.callCenter === "movprod" && this.project === "finance"
        ? "8bac22df-22c4-430c-bb9b-a8705a752559"
        : this.callCenter === "movprod" && this.project === "retail"
        ? "8bac22df-22c4-430c-bb9b-a8705a752559"
        : this.callCenter === "movprod" && this.project === "travel"
        ? "8bac22df-22c4-430c-bb9b-a8705a752559"
        : this.callCenter === "movprod" && this.project === "insurance"
        ? "8bac22df-22c4-430c-bb9b-a8705a752559"
        : this.callCenter === "movprod" && this.project === "telecom"
        ? "8bac22df-22c4-430c-bb9b-a8705a752559"
        : "8bac22df-22c4-430c-bb9b-a8705a752559";
    //moveoIntegratedIdTen
    this.moveoIntegratedIdTen =
      this.callCenter === "movten"
        ? "75b459df-de7f-4f9d-99f4-09e41a9be438"
        : "75b459df-de7f-4f9d-99f4-09e41a9be438";
    /* AWS Connect Configurations PROD*/
    this.AWSsnippetIDProd =
      "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdGdk9FUUNxVTFuSzYvcHhOaTJ0RWc5QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUkt3ZWpDcS9JV2hmNlJtdkFnRVFnQ3ZQM3hvdHdmRE1PUllDcmdwNlY2TlN5SFZEeitERThQcys5alBsdEpvRWk1b3JLMURMOHA3SDczVnc6OkhkU01SWTlOVkIrQjR5Qm5OWUQwNHI4eHVRZGJFUTBKNkdKOXdFUllKMlZWcElsN3ppZmhrQklPa1VTQTh3UnVleGJkSXVIdll6Q212M0dKY012Sk8vanFpQVFzbEdyZTRUNTZnYkxRa0s3OHpyeUNNdjZaNEJaZUc2aWdteXAxakFWbTk4TlNzZmh1Y1JDQ0h3UVhTREdpWGw0TlpWTT0";
    this.AWSconnectIDProd = "e16f5500-d3c8-4689-bf36-3bcb86025b7e";
    this.AWSAuthorizeURLProd =
      "https://pkkwb3ebj7ftlun22d5hr6hcji0gjbkt.lambda-url.us-east-1.on.aws";
    this.AWSClientProd =
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js";

    /* AWS Connect Configurations DEV*/
    this.AWSsnippetIDDev =
      "QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdHRVE0SUJjeWZySEZxRHM5T0VBNkNTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNR2lmcVlzNkFFSmtnY1RJZUFnRVFnQ3VTa0Y2OTRhZ1FZc1V2bEVFajFWR3U2dmIvMXNqUVR5am5mWEdrdFNCUWlzbC9yeHNZcDhBMGVBUG46OkxucnVXZ2RkMkVIWG5aU1RaeDlpNE5rUGo4OHVxWmorMExVSEZwbE9jRHRTcjYyd01DTXRML2VtNlJXa3JHTjVyWWhqV3ZsN0ZPSTBacm1GRCtDcmdIdjNRdkU5bldjWkc2UkJycWZ3d1A4OHFYL1BYU29XMERDKyt4MDJtbE9JUGRFN001bjZmZFRNT0p3WVFqT3g3aDJnVUtQa25wQT0";
    this.AWSconnectIDDev = "69d5653f-e608-4c55-8c59-755ece2bdec6";
    this.AWSAuthorizeURLDev =
      "https://pj6pjtxzw5yu4brhvthp6c4l340xljdv.lambda-url.us-east-1.on.aws";
    this.AWSClientDev =
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js";
    /* AWS Connect Configurations UK*/
    this.AWSsnippetIDUk =
      "QVFJREFIaCtFalN0ZDVNdVlGRUhSSWxuYkZIVi9vOWJuK3J1RHFLMnREVloybXpFTVFFZ1NscjI5NlJxRWp1Wm5Bb1gyOEpvQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUnk5RHY4WlA3K0dCYmU4dkFnRVFnQ3MxZTNoYnMwMHo3NEdkajRjZWlDNE5UaWVvVlNFaFJOOExiVVlaaFZEVlBJcERsZTMrZVVnSWZCWmY6OlNpRGZWcitCbVhsYjZKekhlUDFlMFhtb3V5UEpuN0IyRGx3eE9jMjZvUkZVOEZ3cUJSTDhOOXM2eUNJdlM2MXVIc3FiRUhLNlRoUUpjTHhpOWgzc3JaR1k1YTFTMTQrK0ljTXBNVHlBU3NINHNRRFhNenN0S3VuMkc5d1k1TVpTYTdtay9IRTZkcEVWWXNkZ1VRN21odGhsMVphNDBlcz0";
    this.AWSconnectIDUk = "e5168ae5-1627-4b08-ac9e-c7b9869497d5";
    this.AWSAuthorizeURLUk =
      "https://mlewlqce3ydfjnsigkpdkmzoj40fqqes.lambda-url.eu-west-2.on.aws";
    //"https://hgsuk.uk.auth0.com/samlp/nPSr6QB2ZbtucanvDSLYlNDhGM18vQYI";
    this.AWSClientUk =
      "https://d2zasqxhmd6ne4.cloudfront.net/amazon-connect-chat-interface-client.js";
  }
}
export default new Config();
